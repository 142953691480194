import React from "react";
import { Link, /*useNavigate*/ } from 'react-router-dom';
import '../../styles/home/start.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import sistemaQR from '../../assets/images/nuestras/sistemaQR.png'
import controlAcceso from '../../assets/images/nuestras/controlAcceso.png'
import sistemaMonitoreo from '../../assets/images/nuestras/sistemaMonitoreo.png'
import startImg from '../../assets/images/nuestras/startImg.png'
import vigilancia from '../../assets/images/icon/vigilancia.png'
import biometrico from '../../assets/images/icon/biometrico.png'
import autorizar from '../../assets/images/icon/autorizacion.png'
import bitacora from '../../assets/images/icon/bitacora.png'



function Start() {

    const infoInicio = [

        {
            titulo: "Ayudaremos a mantener protegido tu hogar o empresa",
            informacion: "Tu seguridad siempre será nuestra prioridad",
            src: controlAcceso,
            enlace: "Enlace del boton"
        },
        {
            titulo: "Soliciones de seguridad integradas",
            informacion: "Monitorear tu casa o tu empresa no había sido tan fácil",
            src: sistemaMonitoreo,
            enlace: "Enlace del boton"
        },
        {
            titulo: "Su seguridad es una prioridad y nuestro compromiso",
            informacion: "Confiable y seguro es el QR ya que permite más control sobre las futuras invitaciones",
            src: sistemaQR,
            enlace: "Enlace del boton"
        },

    ]

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        fade: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
    };

    return (
        <div className="main-start">
            <Slider className="" {...settings}>
                <div className="content-start">
                    <div className="inf-start">
                        <div>
                            <h1>PORQUE ESTAMOS SEGUROS DE LO QUE HACEMOS</h1>
                            <div className="iconos-start">
                                <img src={vigilancia} alt="icono de vigilancia"></img>
                                <img src={autorizar} alt="icono de autorizar"></img>
                                <img src={biometrico} alt="icono de sistema biométrico"></img>
                                <img src={bitacora} alt="icono de informe o bitácora"></img>
                            </div>
                        </div>
                        <button>
                            <Link to="/contactos">CONTÁCTANOS</Link>
                        </button>
                    </div>
                    <div className="img-start">
                        <img src={startImg} alt="decoración manrique seguridad"></img>
                    </div>
                </div>
                {infoInicio.map((Contenido, index) => (
                    <div className="content-start">
                        <div className="inf-start">
                            <div>
                                <h1>{Contenido.titulo.toUpperCase()}</h1>
                                <p>{Contenido.informacion}</p>
                            </div>
                            <button>
                                <Link to="/contactos">CONTÁCTANOS</Link>
                            </button>
                        </div>
                        <div className="img-start">
                            <img src={Contenido.src} alt="decoración servicios"></img>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Start;