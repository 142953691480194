import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import MenuServicio from './MenuServicio';
import OptionMonitoreo from './OptionMonitoreo';
import OptionHogar from './OptionHogar';
import OptionOficina from './OptionOficina';
import OptionPinlet from './OptionPinlet';
import "../../../styles/services/optionservicio.css"

function OptionsServicio() {

  useEffect(() => {
    AOS.init({
      once: true, 
    });
  }, []);

  const [visibleOption, setVisibleOption] = useState('pinlet');

  return (
    <div data-aos="fade" className='main-option-servicio'>
      <MenuServicio onOptionClick={setVisibleOption}/>
      <div className="options-servicio">
        <div className={visibleOption === 'monitoreo' ? 'option visible' : 'option invisible'}>
          <OptionMonitoreo/>
        </div>

        <div className={visibleOption === 'hogar' ? 'option visible' : 'option invisible'}>
          <OptionHogar/>
        </div>

        <div  className={visibleOption === 'oficina' ? 'option visible' : 'option invisible'}>
          <OptionOficina/>
        </div>
        
        <div className={`${visibleOption === 'pinlet' ? 'option visible' : 'option invisible'}`}>
          <OptionPinlet/>
        </div>
      </div>
    </div>
  );
}

export default OptionsServicio;