import React from 'react';

function Copyright() {
  return (
    <div className='copyright'>
      <p>copyright ManriqueSeguridad 2024 ©</p>
      <i>todos los derechos reservados</i>
    </div>
  );
}

export default Copyright;