function validateForm(formData) {
  const errors = {};

  if (!formData.nombres.trim()) {
    errors.nombres = "El nombre es obligatorio.";
  }

  if (!formData.correo.trim()) {
    errors.correo = "El correo electrónico es obligatorio.";
  } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
    errors.correo = "El correo electrónico no es válido.";
  }

  if (formData.celular.trim()) {
    if (!/^[\d\s()+-]+$/.test(formData.celular.trim())) {
      errors.celular = "El número telefónico solo puede contener números, espacios, paréntesis, y signos de más o menos.";
    } else if (formData.celular.trim().length < 10) {
      errors.celular = "El número de contacto es inválido, debe tener al menos 10 caracteres.";
    }
  }


  if (!formData.mensaje.trim()) {
    errors.mensaje = "El mensaje es obligatorio.";
  } else if (formData.mensaje.trim().length < 10) {
    errors.mensaje = "El mensaje debe tener al menos 10 caracteres.";
  }

  return errors;
}

export default validateForm;